import { isEmptyOrBlankSpaces } from '@orus.eu/char'
import type { StringSubscriptionUiElement } from '@orus.eu/dimensions'
import { postcodeMapper, type ValidatedTypeMapper } from '@orus.eu/pharaoh'
import { failure, success } from '@orus.eu/result'
import { validateEmail, validatePhoneNumber } from '../../../../lib/validation'

const phoneMapper: ValidatedTypeMapper<string> = {
  inputType: 'tel',
  format: (value) => value,
  formatPlaceholder: (value) => value,
  parse: (text) => {
    const phoneValidationResult = validatePhoneNumber(text)
    return phoneValidationResult.ok ? success(phoneValidationResult.value) : failure(phoneValidationResult.hint)
  },
}

const emailMapper: ValidatedTypeMapper<string> = {
  inputType: 'email',
  format: (value) => value,
  formatPlaceholder: (value) => value,
  formatFailingText: (value) => value.trim().toLowerCase(),
  parse: (text) => {
    const emailValidationResult = validateEmail(text)
    return emailValidationResult.ok ? success(emailValidationResult.value) : failure(emailValidationResult.hint)
  },
}

export const mappers: Record<StringSubscriptionUiElement['type'], ValidatedTypeMapper<string>> = {
  email: emailMapper,
  phone: phoneMapper,
  postcode: postcodeMapper,
  string: {
    inputType: 'text',
    format: (value) => value,
    formatPlaceholder: (value) => value,
    parse: (text) => {
      if (isEmptyOrBlankSpaces(text)) {
        return success(undefined)
      }
      return success(text)
    },
  },
}
